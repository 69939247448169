.Nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  z-index: 1000;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  font-size: 14pt;
  line-height: 1;
  height: 20mm;

  background-color: rgba(#141414, 1.0);

  &__container {
    position: relative;

    display: flex;
    flex-direction: row;
    align-items: center;

    list-style-type: none;

    margin: 0.6cm 0.3cm 0.6cm 0.6cm;

    &_mobile {
      position: fixed;
      top: 20mm;
      right: 0;
      bottom: 0;
      left: 0;

      align-items: center;
      justify-content: center;
      flex-direction: column;

      background-color: rgba(#141414, 1.0);

      margin: 0;
    }
  }

  &__menuButton {
    height: 1cm;
    position: absolute;
    right: 5mm;
    top: 5mm;
  }

  &Link {
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 0 0.6cm;

    transition: opacity 0.15s ease-out 0s;
    
    &__item {
      opacity: 0.7;
      text-decoration: none;
      
      &:hover {
        opacity: 0.8;
      }
      
      &:focus {
        opacity: 0.9;
      }
      
      &:active {
        opacity: 1.0;
      }

      &_magic {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        justify-items: center;

        height: 1.2cm;

        opacity: 1.0;
        text-decoration: none;
        
        &:hover {
          opacity: 0.9;
        }
        
        &:focus {
          opacity: 1.0;
        }
        
        &:active {
          opacity: 1.0;
        }
      }
      
      &_logo {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        justify-items: center;

        height: 1.2cm;

        opacity: 1.0;
        text-decoration: none;
        
        &:hover {
          opacity: 0.9;
        }
        
        &:focus {
          opacity: 1.0;
        }
        
        &:active {
          opacity: 1.0;
        }
      }

      &_name {
        font-family: 'Bebas Neue', sans-serif;
        font-size: 30pt;
        line-height: 0.8;

        margin-left: 4mm
      }

      &_mobile {
        margin: 5mm;
      }
    }

    &_right {
      position: absolute;
      right: 1.6cm;
    }
  }
  
}