@import "src/styles/_colors.scss";

.ContactPage {
  margin-top: 20mm;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &__help {
    width: 10cm;
    padding: 1cm;
  }

  &__form {
    position: relative;
    display: flex;
    flex-direction: column;

    align-items: center;
    margin: 1cm;
    max-width: 100%;
  }

  &__label {
    display: block;
  }

  &__separator {
    margin-bottom: 10mm;
  }

  &__formElement {
    position: relative;
    margin-bottom: 5mm;

    width: calc(100% - 2cm);
  }

  &__button {
    background: $white-10;
    border: 1px $white-40 solid;
  
    border-radius: 6pt;
    padding: 6pt;
  
    color: $text;
  
    &:hover {
      border-color: $white-60;
      background: $white-20;
    }
  
    &:focus {
      outline: none;
      border-color: $primary-80;
      background: $white-20;
    }

    &:active {
      outline: none;
      border-color: $primary-80;
      background: $primary-20;
    }
  }

  &__message {
    width: calc(100% - 16px);
    resize: vertical;

    font-family: "Open Sans", sans-serif;
    font-weight: 300;
  }

  @media (max-width: 1325px) {
    flex-direction: column;
  }

  &__map {
    height: 450px;
    width: 600px;
    border: none;
    margin-right: 16pt;

    max-width: 100vw;

    @media (max-width: 910px) {
      margin-right: 0;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;

    &Line {
      display: flex;
      flex-direction: row;

      align-items: center;
      justify-content: flex-start;

      margin: 6pt;
    }

    &Icon {
      display: flex;
      flex-direction: row;

      align-items: center;
      justify-content: center;

      min-width: 20pt;
    }
  }

  &__section {
    margin: 10mm;
    font-size: 14pt;

    background: transparent;

    &:nth-child(even) {
      background: #0d0d0d;
    }

    &Title {
      font-family: "Bebas Neue", sans-serif;
      padding: 0 0 24pt 0;
      margin: 0 auto;
      text-align: center;

      font-size: 32pt;
    }

    &Body {
      width: 210mm;
      max-width: 100vw;

      margin: auto;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      @media (max-width: 910px) {
        flex-direction: column;
        row-gap: 1cm;
        margin: 0;
      }
    }
  }
}