@import "/src/styles/_colors.scss";

* {
  padding: 0;
  margin: 0;
}

body {
  width: 100vw;

  margin: 0;
  font-family: 'Open Sans', monospace;
  font-weight: 300;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: $text;
  fill: #FFFFFF;
  background: #121212;

  overflow-x: hidden;
}

a {
  color: $text;
}
