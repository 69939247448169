@import "src/styles/_colors.scss";

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.ProductPage {
  margin: auto;
  padding-top: 20mm;

  &_multi {
    position: relative;
    width: 100%;
    max-height: calc(100vh - 20mm);
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-items: center;
    overflow: hidden;
  }
  
  &__productImage {
    position: absolute;
    width: 100vw;
    z-index: -1;
    overflow: hidden;

    @media (max-aspect-ratio: 1260/972) {
      width: auto;
      height: 100vh;
      overflow: hidden;
    }
    
    &_hidden {
      display: none;
    }

    &_raw {
      z-index: -2;
    }

    &_enhanced {
      opacity: 0;
      clip-path: polygon(0% 0%, 50% 0%, 50% 100%, 0% 100%);
      animation: 2s linear 1s fade-in forwards;
    }

    &_events {
      opacity: 0;
      clip-path: polygon(100% 0%, 50% 0%, 50% 100%, 100% 100%);
      animation: 2s linear 1s fade-in forwards;
    }


  }

  &__productContainer {
    width: 100%;
    height: calc(100vh - 20mm);

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__productLink {
    font-family: "Bebas Neue", sans-serif;
    font-size: 48pt;
    opacity: 0.7;
    text-decoration: none;

    width: 100%;
    text-align: center;
    vertical-align: middle;
    height: calc(100vh - 20mm);


    
    &:hover {
      opacity: 0.8;
    }
    
    &:focus {
      opacity: 0.9;
    }
    
    &:active {
      opacity: 1.0;
    }
  }

  &__section {
    padding:20mm;
    font-size: 14pt;

    background: transparent;

    &:nth-child(even) {
      background: #0d0d0d;
    }
  }

  &__heading {
    font-family: 'Bebas Neue', sans-serif;

    &_product {
      text-align: center;
      font-size: 32pt;
      padding: 0 0 24pt 0;
      font-weight: 400;
    }

    &_spec {
      font-size: 20pt;
      font-weight: 400;
      padding: 24pt 0 12pt 0;
    }
  }

  &__sectionBody {
    max-width: 210mm;

    margin: auto;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  &__paragraph {
    font-size: 14pt;
    margin-bottom: 12pt;
  }

  &__footnoteContainer {
    width: 100%;
    font-size: 10pt;
    margin-top: 12pt;
    border-top: 1px solid $white-60;
  }
}

@media (max-width: 830px) {
  .ProductPage {
    margin: auto;
    padding-top: 20mm;
  
    &_multi {
      position: relative;
      width: 100%;
      max-height: calc(100vh - 20mm);
      display: grid;
      grid-template-rows: 1fr 1fr;
      grid-template-columns: 1fr;
    }
    
    &__productImage {
      &_enhanced {
        clip-path: polygon(0% 0%, 100% 0%, 100% 50%, 0% 50%);
      }
  
      &_events {
        clip-path: polygon(0% 100%, 0% 50%, 100% 50%, 100% 100%);
      }
    }
  
    &__productContainer {
      width: 100%;
      height: calc(50vh - 10mm);
  
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    &__productLink {
      font-family: "Bebas Neue", sans-serif;
      font-size: 48pt;
      opacity: 0.7;
      text-decoration: none;
  
      width: 100%;
      text-align: center;
      vertical-align: middle;
      height: calc(50vh - 10mm);
      
      &:hover {
        opacity: 0.8;
      }
      
      &:focus {
        opacity: 0.9;
      }
      
      &:active {
        opacity: 1.0;
      }
    }
  }
}