$black: #000;

$white: #FFF;
$white-100: rgba($white, 1.0);
$white-80: rgba($white, 0.8);
$white-60: rgba($white, 0.6);
$white-40: rgba($white, 0.4);
$white-20: rgba($white, 0.2);
$white-10: rgba($white, 0.1);

$primary: #52a6ef;
$primary-100: rgba($primary, 1.0);
$primary-80: rgba($primary, 0.8);
$primary-60: rgba($primary, 0.6);
$primary-40: rgba($primary, 0.4);
$primary-20: rgba($primary, 0.2);
$primary-10: rgba($primary, 0.1);

$success: #28a745;
$success-100: rgba($success, 1.0);
$success-80: rgba($success, 0.8);
$success-60: rgba($success, 0.6);
$success-40: rgba($success, 0.4);
$success-20: rgba($success, 0.2);
$success-10: rgba($success, 0.1);

$error: #FF0000;
$error-100: rgba($error, 1.0);
$error-80: rgba($error, 0.8);
$error-60: rgba($error, 0.6);
$error-40: rgba($error, 0.4);
$error-20: rgba($error, 0.2);
$error-10: rgba($error, 0.1);

$failure: $error;
$failure-100: rgba($failure, 1.0);
$failure-80: rgba($failure, 0.8);
$failure-60: rgba($failure, 0.6);
$failure-40: rgba($failure, 0.4);
$failure-20: rgba($failure, 0.2);
$failure-10: rgba($failure, 0.1);

$danger: $error;
$danger-100: rgba($danger, 1.0);
$danger-80: rgba($danger, 0.8);
$danger-60: rgba($danger, 0.6);
$danger-40: rgba($danger, 0.4);
$danger-20: rgba($danger, 0.2);
$danger-10: rgba($danger, 0.1);

$warning: #ffc107;
$warning-100: rgba($warning, 1.0);
$warning-80: rgba($warning, 0.8);
$warning-60: rgba($warning, 0.6);
$warning-40: rgba($warning, 0.4);
$warning-20: rgba($warning, 0.2);
$warning-10: rgba($warning, 0.1);

$background: $black;
$text: rgba($white, 0.8);