.Carousel {
  overflow: hidden;

  &__container {
    overflow: hidden;
    display: flex;

    height: calc(100vh - 20mm);
    max-width: 100vw;
  }

  &__item {
    position: absolute;
    left: 0;

    min-width: 100vw;
    max-width: 100vw;
    height: calc(100vh - 20mm);

    transition: left 0.6s ease-in-out;
  }

  &__itemFrame {
    min-width: 100vw;
    height: calc(100vh - 20mm);
  }

  &__arrow {
    position: absolute;
    top: calc(50vh - 30mm);

    fill: #FFFFFF;
    opacity: 0.5;
    height: 1cm;

    padding: 1cm;

    &:hover {
      cursor: pointer;
      opacity: 0.6;
    }

    &_left {
      left: 5mm;
    }

    &_right {
      right: 5mm;
    }
  }

  &__pipContainer {
    position: absolute;
    top: 35mm;
    width: 100vw;
    
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__pip {
    cursor: pointer;
    
    height: 3mm;
    z-index: 1000;

    fill: white;
    opacity: 0.5;
    
    margin: 3mm;

    &:hover {
      opacity: 0.7;
    }

    &:active {
      opacity: 0.9
    }

    &_active {
      opacity: 0.8;

      &:hover {
        opacity: 0.9
      }
    }
  }
}