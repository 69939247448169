.AboutPage {
  margin-top: 20mm;

  &__section {
    padding:20mm;
    font-size: 14pt;

    background: transparent;

    &:nth-child(even) {
      background: #0d0d0d;
    }

    &Title {
      font-family: "Bebas Neue", sans-serif;
      padding: 0 0 24pt 0;
      margin: 0 auto;
      text-align: center;

      font-size: 32pt;
    }

    &Body {
      max-width: 210mm;

      margin: auto;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    &Block {

    }

  }

  &__paragraph {
    margin-bottom: 12pt;
  }

  &__idea {
    font-family: "Bebas Neue";
    margin-bottom: 4mm;
  }

  &__bullet {
    position: relative;
    margin-left: 6mm;
    margin-bottom: 8mm;
    text-indent: -2mm;

    &::before {
      position: relative;
      left: -4mm;
      content: "•";

    }
  }
}