@keyframes slide-out {
  0% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }

  45% {
    clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
  }

  55% {
    clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
  }

  100% {
    clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
  }
}

.HomePage {
  width: 100vw;
  height: calc(100vh - 20mm);
  margin-top: 20mm;

  overflow: hidden;

  &__splash {
    position: relative;
    width: 100vw;
    height: calc(100vh - 20mm);

    &Text {
      position: absolute;
      left: 15%;
      max-width: 70%;
      top: 35%;

      z-index: 3;
    }

    &Name {
      font-family: 'Bebas Neue', sans-serif;

      font-size: 48pt;
      margin-bottom: 20pt;
    }

    &Tagline {
      font-size: 16pt;
      font-weight: bold;
    }

    &Container {
      position: absolute;
      overflow: hidden;

      height: calc(100vh - 20mm);
      width: 100vw;
    }

    &Image {
      height: calc(110vh);
      position: fixed;
      top: 20mm;
      right: 0;
      overflow: hidden;

      &_reference {
        z-index: 2;
        animation: 10s ease-in 1s infinite both slide-out alternate;
      }

      &_enhanced {
        z-index: 1;
      }
    }
  }

}

@media (max-width: 625px) {
  .HomePage__sectionBody {
    flex-direction: column;
  }

}