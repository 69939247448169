.GalleryPage {
  margin-top: 20mm;
}

.Example {
  &__content {
    position: relative;
    width: 100%;
    height: calc(100vh - 20mm);


    &_images {
      display: grid;
      grid-template-columns: 50% 50%;
  
      place-items: center;
    }

    &_embed {
      display: block;
    }
  }

  &__name {
    position: absolute;

    z-index: 100;

    top: 10mm;
    width: 100%;

    display: flex;
    justify-content: center;

    font-family: "Bebas Neue", sans-serif;
    font-size: 24pt;
  }

  &__image {
    width: 100%;
    &_raw {
      grid-column: 1 / 2;
    }

    &_enhanced {
      grid-column: 2 / 3;
    }
  }
}