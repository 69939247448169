.WhitePaperPage {
  position: fixed;
  top: 20mm;

  width: 100vw;
  height: calc(100vh - 20mm);



  &__pdfContainer {
    position: fixed;
    top: 20mm;
  
    width: 100vw;
    height: calc(100vh - 20mm);

    border: none;
  }
}